/**
 * Máscaras MKX
 */
// A validação da máscara é feita no evento Blur.
// É necessária apenas a classe correspondente no input e seguir a formatação html de forms-group do bootstrap.
// É possivel adicionar o atributo "data-blur-alert" no input para customizar a mensagem de erro.
// Ao acionar o evento, é retirado do valor inserido tudo que não seja númerico.
// Após a validação do campo, pode-se ter sucesso ou erro.
// - Erro: Chama a função addError.
// - Sucesso: Monta a máscara no valor inserido e remove possíveis mensagens de erro.

$(document).ready(function() {

    // CPF
    $('body').on('blur', '.mkx-cpf-mask', function() {
        var value = $(this).val();
        if (value.length != 0) {
            value = value.replace(/\D/g, '');
            if (value.length != 11) {
                addError($(this), "CPF inválido");
            } else {
                var value1 = value.slice(0, 3);
                var value2 = value.slice(3, 6);
                var value3 = value.slice(6, 9);
                var value4 = value.slice(9, 11);
                value = value1 + "." + value2 + "." + value3 + "-" + value4;
                $(this).val(value);
                if ($(this).next().hasClass('alert')) {
                    $(this).next().remove();
                }
            }
        }
    });

    $('body').on('change','.mkx-cardholer-mask', function(){
		const value = $(this).val();
        const regex = new RegExp(/^[a-zA-Z\s]+$/)
        if (!regex.test(value)) {
            addError($(this),"Nome do titular sem acentos ou símbolos");
        } else {
            if($(this).next().hasClass('alert')){
                $(this).next().remove();
            }
            return true;
        }
	});
    
    // Cartão (exceto amex e diners)
	$('body').on('blur','.mkx-card-mask',function(){
		var value = $(this).val();
		if(value.length != 0){
			if(!isValidCreditCard(value)){
				addError($(this),"Cartão inválido");
			}else{
				value = value.replace(/\D/g,'');
				var value1 = value.slice(0,4);
				var value2 = value.slice(4,8);
				var value3 = value.slice(8,12);
				var value4 = value.slice(12,16);
				value = value1+" "+value2+" "+value3+" "+value4;
				$(this).val(value);
				if($(this).next().hasClass('alert')){
					$(this).next().remove();
				}
			}
		}
	});

    // Cartão Amex e Diners
    $('body').on('blur', '.mkx-card-2-mask', function() {
        var value = $(this).val();
        if (value.length != 0) {
            value = value.replace(/\D/g, '');
            if (value.length < 14 || value.length > 16) {
                addError($(this), "Cartão inválido");
            } else {
                $(this).val(value);
                if ($(this).next().hasClass('alert')) {
                    $(this).next().remove();
                }
            }
        }
    });

    // Código de segurança do cartão - 3 dígitos
    $('body').on('blur', '.mkx-card-secure-3-mask', function() {
        var value = $(this).val();
        if (value.length != 0) {
            value = value.replace(/\D/g, '');
            if (value.length != 3) {
                addError($(this), "Código inválido");
            } else {
                $(this).val(value);
                if ($(this).next().hasClass('alert')) {
                    $(this).next().remove();
                }
            }
        }
    });

    // Código de segurança do cartão - 4 dígitos
    $('body').on('blur', '.mkx-card-secure-4-mask', function() {
        var value = $(this).val();
        if (value.length != 0) {
            value = value.replace(/\D/g, '');
            if (value.length != 4) {
                addError($(this), "Código inválido");
            } else {
                $(this).val(value);
                if ($(this).next().hasClass('alert')) {
                    $(this).next().remove();
                }
            }
        }
    });

    // Vencimento do cartão
    $('body').on('blur', '.mkx-venc-mask', function() {
        var value = $(this).val();
        if (value.length != 0) {
            value = value.replace(/\D/g, '');
            if (value.length != 6 && value.length != 4) {
                //if(value.length != 4){
                addError($(this), "Data inválida");
            } else {
                if (value.length == 6) {
                    var value1 = value.slice(0, 2);
                    var value2 = value.slice(2, 6);
                    value = value = value1 + "/" + value2;
                } else {
                    var value1 = value.slice(0, 2);
                    var value2 = value.slice(2, 4);
                    value = value = value1 + "/20" + value2;
                }
                $(this).val(value);
                if ($(this).next().hasClass('alert')) {
                    $(this).next().remove();
                }
            }
        }
    });

    // CNPJ
    $('body').on('blur', '.mkx-cnpj-mask', function() {
        var value = $(this).val();
        if (value.length != 0) {
            value = value.replace(/\D/g, '');
            if (value.length != 14) {
                addError($(this), "CNPJ inválido");
            } else {
                var value1 = value.slice(0, 2);
                var value2 = value.slice(2, 5);
                var value3 = value.slice(5, 8);
                var value4 = value.slice(8, 12);
                var value5 = value.slice(12, 14);
                value = value1 + "." + value2 + "." + value3 + "/" + value4 + "-" + value5;
                $(this).val(value);
                if ($(this).next().hasClass('alert')) {
                    $(this).next().remove();
                }
            }
        }
    });

    // Data
    $('body').on('blur', '.mkx-date-mask', function() {
        var value = $(this).val();
        if (value.length != 0) {
            value = value.replace(/\D/g, '');
            if (value.length != 8) {
                addError($(this), "Data inválida");
            } else {
                var value1 = value.slice(0, 2);
                var value2 = value.slice(2, 4);
                var value3 = value.slice(4, 8);
                value = value1 + "/" + value2 + "/" + value3;
                $(this).val(value);
                if ($(this).next().hasClass('alert')) {
                    $(this).next().remove();
                }
            }
            if($(this).attr("id") === "personBirthday") {
				checkBirthDay = verifyBirthDay($(this).val());
				if( checkBirthDay !== undefined && (typeof checkBirthDay === "string") ) {
					addError($(this), checkBirthDay);
				}
			}
        }
    });

    // Cep
    $('body').on('blur', '.mkx-zipcode-mask', function() {
        var value = $(this).val();
        if (value.length != 0) {
            value = value.replace(/\D/g, '');
            if (value.length != 8) {
                addError($(this), "CEP inválido");
            } else {
                var value1 = value.slice(0, 5);
                var value2 = value.slice(5, 8);
                value = value1 + "-" + value2;
                $(this).val(value);
                if ($(this).next().hasClass('alert')) {
                    $(this).next().remove();
                }
            }
        }
    });

    // Telefone
    $('body').on('blur', '.mkx-phone-mask', function() {
        var value = $(this).val();
        if (value.length != 0) {
            value = value.replace(/\D/g, '');
            if (value.length != 11 && value.length != 10) {
                addError($(this), "Telefone inválido");
            } else {
                if (value.length == 10) {
                    var value1 = value.slice(0, 2);
                    var value2 = value.slice(2, 6);
                    var value3 = value.slice(6, 10);
                    value = "(" + value1 + ") " + value2 + "-" + value3;
                } else {
                    var value1 = value.slice(0, 2);
                    var value2 = value.slice(2, 7);
                    var value3 = value.slice(7, 11);
                    value = "(" + value1 + ") " + value2 + "-" + value3;
                }
                $(this).val(value);
                if ($(this).next().hasClass('alert')) {
                    $(this).next().remove();
                }
            }
        }
    });

    // E-mail
    $('body').on('blur', '.mkx-email-mask', function() {
        var value = $(this).val();
        if (value.length != 0) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
            if (!re.test(value)) {
                addError($(this), "E-mail inválido");
            } else {
                if ($(this).next().hasClass('alert')) {
                    $(this).next().remove();
                }
            }
        }
    });

    // Cupom
    /*	$('body').on('blur','.mkx-coupon-mask',function(){
    		var value = $(this).val();
    		if(value.length != 0){
    			if(value.length != 14){
    				addError($(this),"Cupom inválido");
    			}else{
    				var value1 = value.slice(0,4);
    				var value2 = value.slice(4,8);
    				var value3 = value.slice(8,14);
    				value = value1+"-"+value2+"-"+value3;
    				$(this).val(value);
    				if($(this).next().hasClass('alert')){
    					$(this).next().remove();
    				}
    			}
    		}
    	});
    */
});
export function verifyBirthDay(date){
	if(typeof date === "string") {
		let dataArray = date.split("/");
		try {	
			if(+dataArray[0] <= 0 || +dataArray[0] > 31) throw ("Esse dia do mês é inválido");
			if(+dataArray[1] <= 0 || +dataArray[1] > 12) throw ("Esse mês é inválido");
			if(+dataArray[2] < 1900) throw ("Esse ano é inválido");
		} catch (err) {
			return err;
		}			
	}
}
/**
 * addErro
 *
 * @field: campo ao qual será adicionado o erro e herdará possível mensagem de erro customizada
 * @msg: mensagem de erro a ser inserida após o campo. Procura no 'field' se há uma mensagem de erro customizada, para sobrepor a padrão chamada na validação da máscara.
 */
export function addError(field, msg) {

    // Limpa o campo
    field.val('');

    // Adiciona a classe de erro no grupo do campo
    field.parent().parent().addClass('has-error');

    // Se já não tiver a mensagem de erro
    if (!field.next().hasClass('alert')) {

        // Verifica se tem o atributo para customizar mensagem de erro e se o mesmo não é vazio. Se sim, sobrepõe a mensagem padrão.
        if (!!field.attr('data-blur-alert')) {
            msg = field.attr('data-blur-alert');
        }

        // Estrutura html da mensagem de erro.
        var htmlStructure = '<div class="alert alert-danger">' + msg + '</div>';

        // Adiciona o erro logo após o campo
        field.after(htmlStructure);

    }

}

export function isValidCreditCard(value) {
	if (/[^0-9-\s]+/.test(value)) return false;
	// The Luhn Algorithm. It's so pretty.
	let nCheck = 0, bEven = false;
	value = value.replace(/\D/g, "");

	for (var n = value.length - 1; n >= 0; n--) {
		var cDigit = value.charAt(n),
			  nDigit = parseInt(cDigit, 10);

		if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

		nCheck += nDigit;
		bEven = !bEven;
	}

	return (nCheck % 10) == 0;
}